import React from 'react';
import { AppBar, Typography } from '@mui/material';
// import CloseIcon from '@mui/icons-material/Close';

const AlertBanner = () => {
  // If we want to control visibility to close
  // const [isVisible, setIsVisible] = useState(true);

  // Use env to handle text
  const bannerText = process.env.REACT_APP_BANNER_TEXT || ''

  // const closeBanner = () => {
  //   setIsVisible(false); // Hide the banner when close button is clicked
  // };

  return (
      <AppBar
        position="static"
        color="warning"
        sx={{
          height: '35px',
          alignItems: "center",
        }}>
        <Typography variant="subtitle1" sx={{ flexGrow: 1, mt: '4px' }}>
          {bannerText}
        </Typography>
        {/* <IconButton edge="end" color="inherit" onClick={closeBanner}>
            <CloseIcon />
          </IconButton> */}
      </AppBar>
    )
};

export default AlertBanner;
