import mixpanel from "../services/mixpanel"

export function handleMixpanelQuoteEvent(applicationId, attributeName, attributeValue) {
    if (!attributeValue || attributeValue === "") {
        return mixpanelEvent(applicationId, attributeName, attributeValue, "Added")
    }

    return mixpanelEvent(applicationId, attributeName, attributeValue, "Updated")
}

function mixpanelEvent(applicationId, attributeName, attributeValue, eventType) {
    return mixpanel.track(`Quote page: ${eventType} ${attributeName}`, {
        applicationId,
        value: attributeValue
    })
}