import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";
import storage from "redux-persist-indexeddb-storage";
// import storage from "redux-persist/lib/storage";
import { persistStore, persistReducer } from "redux-persist";
import { formdataSlice } from "./slices/applicationFormSlice";
import { applicationsSlice } from "./slices/applicationsSlice";
import { customerSlice } from "./slices/customerSlice";
import { userSlice } from "./slices/userSlice";
import { usersSlice } from "./slices/usersSlice";
import { organisationSlice } from "./slices/organisationSlice";
import { applicationsAssetSlice } from "./slices/applicationAssetSlice";
import { quoteSlice } from "./slices/quoteSlice";
import { calculatorSlice } from "./slices/calculatorSlice";
import { lenderApi } from "./slices/apiSlice";
// import { lenderChunksSlice } from "./slices/lenderChunkSlice";
import { expirationMiddleware } from "./middleware/expirationMiddleware";

const persistConfig = {
  key: "root",
  storage: storage("financeableDB"),
  // storage,
  whitelist: ["lenderApi"], // Add the slices you want to persist
  transforms: [
    {
      in: (state, key) => ({ ...state, timestamp: Date.now() }),
      out: (state, key) => state,
    },
  ],
};

// const lenderReducer = {
//   lenderChunks: lenderChunksSlice.reducer,
// };

const rootReducer = combineReducers({
  userDetails: formdataSlice.reducer,
  applications: applicationsSlice.reducer,
  assert: applicationsAssetSlice.reducer,
  user: userSlice.reducer,
  Quotes: quoteSlice.reducer,
  users: usersSlice.reducer,
  customer: customerSlice.reducer,
  organisation: organisationSlice.reducer,
  calculator: calculatorSlice.reducer,
  [lenderApi.reducerPath]: lenderApi.reducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }).concat(lenderApi.middleware, expirationMiddleware),
});

const persistor = persistStore(store);

setupListeners(store.dispatch);

export { store, persistor };
