import React from "react";
import HtmlTwoToneIcon from "@mui/icons-material/HtmlTwoTone";
import PictureAsPdfTwoToneIcon from "@mui/icons-material/PictureAsPdfTwoTone";
import DescriptionIcon from '@mui/icons-material/Description';
import { Card, CardActionArea, Link, Paper, Stack, Typography } from "@mui/material";

const ext = [".jpg", ".jpeg", ".png", ".gif"];

const nameOfDocUploadComponent = [
  "privacyAgreement",
  // "identity",
  // "creditProfile",
  // "financialStatement",
  "otherDocuments",
];

export default function Document({ document, name }) {
  if (nameOfDocUploadComponent.includes(name) && !document.url) {
    return null;
  }

  return (
    <React.Fragment>
      <Link href={document.url} size="small" color="secondary" style={{ textDecoration: "none" }}>
        <Stack direction="column" style={{ alignItems: "center" }}>
          <Card
            elevation={1}
            item
            xs={3}
            style={{
              height: "100px",
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
              flexDirection: "column",
              // background: "transparent",
              padding: 5,
              objectFit: "cover",
            }}
          >
            <CardActionArea>
              <ImageComponent doc={document} />
            </CardActionArea>
          </Card>
          <Typography variant="subtitle2" textAlign="center">
            {document?.name}
          </Typography>
        </Stack>
      </Link>
    </React.Fragment>
  );
}

const ImageComponent = ({ width, doc }) => {

  const getDocIcon = (docExtension) => {
    switch (docExtension) {
      case ".html":
        return <HtmlTwoToneIcon fontSize="large" />
      case ".csv":
        return <DescriptionIcon />
      case ".pdf":
        return <PictureAsPdfTwoToneIcon />
    
      default:
        return  <PictureAsPdfTwoToneIcon />
    }
  }

  if (doc?.ext === ".webm") {
    return (
      <video
        src={doc?.url}
        style={{
          minWidth: "76px",
          maxWidth: "76px",
        }}
      ></video>
    );
  }

  if (ext.includes(doc?.ext))
    return (
      <Paper
        elevation={0}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginBottom: "5px",
          background: `url(${doc?.url})`,
          backgroundPosition: "center center",
          backgroundSize: "contain",
          backgroundRepeat: "no-repeat",
          padding: "5px",
          minHeight: "86px",
          minWidth: "76px",
          maxWidth: "76px",
        }}
      ></Paper>
    );

  return (
    <Paper
      elevation={0}
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        marginBottom: "5px",
        background: "transparent",
        padding: "30px 25px",
        minWidth: "76px",
        maxWidth: "76px",
      }}
    >
      {getDocIcon(doc?.ext)} 
    </Paper>
  );
};
