import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Alert, Box, Grid, Slider, Stack, Typography } from "@mui/material";
// eslint-disable-next-line import/no-unresolved
import { fCurrency } from "src/utils/formatNumber";
import Assets from "./Assets";
import Liabilities from "./Liabilities";
import Income from "./Income";
import Expenses from "./Expenses";
import { APPLICATION_TYPES, HEM_CONSTANTS } from "../../constants";
import { saveCustomerProfile, userDetailsSelector } from "../../store/slices/applicationFormSlice";
// import { clearUpFinances } from "../../store/slices/applicationFormSlice";

const CustomerPersonalFinances = React.memo(({ customer, applicationType, applicationId, customerIndex }) => {
  const { personalFinLoaders } = useSelector(userDetailsSelector);
  const { customerProfile } = customer;
  const [sharedExpenses, setSharedExpenses] = useState(customerProfile?.shareOfExpenses ?? 0);
  const dispatch = useDispatch();

  const computeServiceability = () => {
    const amountByFrequency = (frequency, amount) => {
      // returns the amount depending on set frequency of payment
      switch (frequency) {
        case "Monthly":
          return parseFloat(amount);
        case "Annually":
          return parseFloat(amount) / 12;
        case "Weekly":
          return (parseFloat(amount) * 52) / 12;
        case "Fortnightly":
          return (parseFloat(amount) * 26) / 12;
        default:
          return parseFloat(amount);
      }
    };
    const income = parseFloat(
      customer.income
        .map((income) => amountByFrequency(income.frequency, isNaN(parseFloat(income.amount)) ? 0 : income.amount))
        .reduce((accumulator, currentValue) => accumulator + currentValue, 0),
    ).toFixed(2);

    const hemConstant = HEM_CONSTANTS.find(
      (hem) => hem.minIncome <= parseFloat(income) && hem.maxIncome >= parseFloat(income),
    );

    const { customerProfile } = customer;

    let liabilities = parseFloat(
      customer.liabilities
        .map((liability) =>
          amountByFrequency(
            liability.repaymentFrequency,
            isNaN(parseFloat(liability.repaymentAmount)) ? 0 : liability.repaymentAmount,
          ),
        )
        .reduce((accumulator, currentValue) => accumulator + currentValue, 0),
    ).toFixed(2);

    // liability deductions after setting shared expenses
    liabilities = (parseFloat(liabilities) - parseFloat(liabilities) * sharedExpenses).toFixed(2);

    const isMarried = (customerProfile?.maritalStatus ?? "") === "Married/Defacto";

    let IS_HEM_GREATER = false;

    let statedExpenses = customer.expenses
      .map((expense) => amountByFrequency(expense.frequency, isNaN(parseFloat(expense.amount)) ? 0 : expense.amount))
      .reduce((accumulator, currentValue) => accumulator + currentValue, 0);

    const computeHEMOrStatedExpenses = () => {
      // let statedExpenses = customer.expenses
      //   .map((expense) => amountByFrequency(expense.frequency, isNaN(parseFloat(expense.amount)) ? 0 : expense.amount))
      //   .reduce((accumulator, currentValue) => accumulator + currentValue, 0);

      const ratesWithChildrenLength = Object.keys(
        isMarried ? hemConstant?.coupleWithChildren : hemConstant?.singleWithChildren,
      ).length; // gets available number of dependants

      const lastKeyOnChildrenRate = Object.keys(
        isMarried ? hemConstant?.coupleWithChildren : hemConstant?.singleWithChildren,
      )[ratesWithChildrenLength - 1]; // gets last key on number of dependants for HEM children rates

      const rentExpense = customer.expenses?.find((expense) => expense.expensesType === "Rent");

      const rentExpenseAmount =
        rentExpense && rentExpense?.frequency
          ? parseFloat(amountByFrequency(rentExpense.frequency, +rentExpense.amount))
          : 0;

      statedExpenses = statedExpenses - rentExpenseAmount;

      const householdExpenses = statedExpenses - statedExpenses * sharedExpenses;

      // function with shared expenses
      const hemOrExpenseRate = (rate) => {
        IS_HEM_GREATER = compareExpenses(rate);
        if (IS_HEM_GREATER) return rate - rate * sharedExpenses; // + rentExpenseAmount
        return householdExpenses;
      };

      // function that compares HEM constant value and stated expenses
      const compareExpenses = (rate) => parseFloat(rate) >= parseFloat(statedExpenses);

      if (customerProfile?.numberOfDependants === 0) {
        IS_HEM_GREATER = compareExpenses(isMarried ? hemConstant?.coupleRate : hemConstant?.singleRate);
        return {
          value: hemOrExpenseRate(isMarried ? hemConstant?.coupleRate : hemConstant?.singleRate),
          rentExpenseAmount: rentExpenseAmount,
          hemValue: isMarried ? hemConstant?.coupleRate : hemConstant?.singleRate,
          householdExpenses,
        };
      } else if (
        customerProfile?.numberOfDependants > 0 &&
        customerProfile?.numberOfDependants <= parseInt(lastKeyOnChildrenRate)
      ) {
        IS_HEM_GREATER = compareExpenses(
          isMarried
            ? hemConstant?.coupleWithChildren[customerProfile?.numberOfDependants.toString()]
            : hemConstant?.singleWithChildren[customerProfile?.numberOfDependants.toString()],
        );
        return {
          value: hemOrExpenseRate(
            isMarried
              ? hemConstant?.coupleWithChildren[customerProfile?.numberOfDependants.toString()]
              : hemConstant?.singleWithChildren[customerProfile?.numberOfDependants.toString()],
          ),
          rentExpenseAmount: rentExpenseAmount,
          hemValue: isMarried
            ? hemConstant?.coupleWithChildren[customerProfile?.numberOfDependants.toString()]
            : hemConstant?.singleWithChildren[customerProfile?.numberOfDependants.toString()],
          householdExpenses,
        };
      } else {
        const childrenRate = isMarried
          ? hemConstant?.coupleWithChildren[lastKeyOnChildrenRate]
          : hemConstant?.singleWithChildren[lastKeyOnChildrenRate];
        const numberOfDependantDifference = customerProfile?.numberOfDependants - parseInt(lastKeyOnChildrenRate);
        const additionalRate = numberOfDependantDifference * hemConstant?.additionalChild + childrenRate;
        IS_HEM_GREATER = compareExpenses(additionalRate);
        return {
          value: hemOrExpenseRate(additionalRate),
          rentExpenseAmount: rentExpenseAmount,
          hemValue: additionalRate,
          householdExpenses,
        };
      }
    };

    const hemOrStatedExpenses =
      hemConstant && customerProfile?.maritalStatus ? computeHEMOrStatedExpenses() : { value: statedExpenses };

    const expenses = parseFloat(
      parseFloat(liabilities) + (hemOrStatedExpenses?.value ?? 0) + (hemOrStatedExpenses?.rentExpenseAmount ?? 0),
    ).toFixed(2);

    const surplus = (parseFloat(income) - parseFloat(expenses)).toFixed(2);

    return {
      income,
      liabilities,
      hemOrStatedExpenses,
      expenses,
      surplus,
      hemConstant,
      IS_HEM_GREATER,
    };
  };

  const Serviceability = React.memo(() => {
    const { expenses, hemOrStatedExpenses, income, liabilities, surplus, IS_HEM_GREATER } = computeServiceability();
    const isMarried = (customerProfile?.maritalStatus ?? "") === "Married/Defacto";

    return (
      <Stack direction={"column"} width={"50%"} gap={"16px"}>
        <Typography fontWeight={700} fontSize={"14px"}>
          Serviceability (monthly)
        </Typography>
        <Stack direction={"column"} gap={"8px"}>
          <Stack direction={"row"} justifyContent={"space-between"}>
            <Typography fontSize={"14px"}>Total Net Income:</Typography>
            <Typography fontSize={"14px"}>{fCurrency(income)}</Typography>
          </Stack>
          <Stack direction={"row"} justifyContent={"space-between"}>
            <Typography fontSize={"14px"}>Total Liabilities Repayments:</Typography>
            <Typography fontSize={"14px"}>{fCurrency(liabilities)}</Typography>
          </Stack>

          <Stack direction={"row"} justifyContent={"space-between"} color={!IS_HEM_GREATER ? "#BFBFBF" : "black"}>
            {customerProfile?.maritalStatus ? (
              <Typography fontSize={"14px"}>
                {!isMarried
                  ? `HEM (single) w/ ${customerProfile?.numberOfDependants} ${customerProfile?.numberOfDependants > 1 ? "children" : "child"}`
                  : `HEM (couple) w/ ${customerProfile?.numberOfDependants} ${customerProfile?.numberOfDependants > 1 ? "children" : "child"}`}
              </Typography>
            ) : (
              <Typography fontSize={"14px"}>HEM</Typography>
            )}

            <Typography fontSize={"14px"}>
              {fCurrency(hemOrStatedExpenses?.hemValue?.toFixed(2)) ?? Number(0).toFixed(2)}
            </Typography>
          </Stack>

          <Stack
            direction={"row"}
            justifyContent={"space-between"}
            color={IS_HEM_GREATER ? "#BFBFBF" : "black"}
            sx={{ textDecoration: "line-through" }}
          >
            <Typography fontSize={"14px"}>{"Stated Household Expenses"}</Typography>
            <Typography fontSize={"14px"}>
              {fCurrency(
                !IS_HEM_GREATER
                  ? hemOrStatedExpenses?.value?.toFixed(2)
                  : hemOrStatedExpenses?.householdExpenses?.toFixed(2),
              ) ?? Number(0).toFixed(2)}
            </Typography>
          </Stack>
          <Stack direction={"row"} justifyContent={"space-between"}>
            <Typography fontSize={"14px"}>Total Expenses:</Typography>
            <Typography fontSize={"14px"}>{fCurrency(expenses)}</Typography>
          </Stack>
        </Stack>
        <Stack direction={"row"} justifyContent={"space-between"}>
          <Typography fontWeight={700} fontSize={"14px"}>
            Surplus:
          </Typography>
          <Typography fontWeight={700} fontSize={"14px"} color={parseFloat(surplus) >= 0 ? "green" : "red"}>
            {fCurrency(surplus)}
          </Typography>
        </Stack>
      </Stack>
    );
  });

  const handle = {
    shareOfExpenses: (event, value) => {
      if (customer?.customerProfile?.shareOfExpenses === value) {
        return;
      }
      dispatch(
        saveCustomerProfile({
          shareOfExpenses: value,
          customer: customer._id,
        }),
      );
    },
  };

  return (
    // const dispatch = useDispatch();

    // const handleCleanUpField = () => {
    // dispatch(clearUpFinances());
    // };

    <React.Fragment>
      <Grid
        container
        item
        xs={12}
        // spacing={3}
        style={{
          margin: "0 0 30px 0",
          borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
          paddingBottom: "10px",
        }}
      >
        <Grid item md={12} sm={12}>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Typography fontWeight={600}>Personal Finances</Typography>
            {/* <Button onClick={handleCleanUpField} variant="outlined">
              Remove all field data
            </Button> */}
          </div>
        </Grid>

        <Grid container item xs={12} mb={"24px"}>
          <Assets
            customerIndex={customerIndex}
            assets={customer.customerAssets}
            customerId={customer._id}
            applicationId={applicationId}
            personalFinLoaders={personalFinLoaders}
          />
          <Liabilities
            liabilities={customer.liabilities}
            customerId={customer._id}
            customerIndex={customerIndex}
            applicationId={applicationId}
            personalFinLoaders={personalFinLoaders}
          />

          {(applicationType === APPLICATION_TYPES.CONSUMER || applicationType === APPLICATION_TYPES.PERSONAL) && (
            <React.Fragment>
              <Income
                income={customer.income}
                customerId={customer._id}
                customerIndex={customerIndex}
                applicationId={applicationId}
                personalFinLoaders={personalFinLoaders}
              />

              <Expenses
                expenses={customer.expenses}
                customerId={customer._id}
                customerIndex={customerIndex}
                applicationId={applicationId}
                personalFinLoaders={personalFinLoaders}
              />
              <Grid container item xs={12} my={"24px"}>
                <Box width={{ xs: "80%", md: "40%" }}>
                  <Typography mb={"12px"} fontWeight={700} fontSize={"14px"}>
                    Shared Expenses
                  </Typography>
                  <Box ml={"10px"}>
                    <Slider
                      required
                      color="secondary"
                      size="large"
                      track={false}
                      marks={[
                        { value: 0, label: "0%" },
                        { value: 0.25, label: "25%" },
                        { value: 0.5, label: "50%" },
                        { value: 0.75, label: "75%" },
                        { value: 1, label: "100%" },
                      ]}
                      step={0.05}
                      min={0}
                      max={1}
                      aria-label="Default"
                      valueLabelDisplay="auto"
                      onChange={(event) => setSharedExpenses(parseFloat(event.target.value))}
                      defaultValue={sharedExpenses}
                      onChangeCommitted={handle.shareOfExpenses}
                    />
                  </Box>
                </Box>
              </Grid>
              <Grid container item xs={12} md={12} style={{ padding: "0 0 15px 0" }}>
                <Serviceability />
              </Grid>
              <Grid container item xs={12} md={12}>
                <Stack>
                  <Alert
                    severity="info"
                    sx={{
                      padding: '2px 8px',
                      fontSize: '0.8rem',
                      minHeight: 'unset',
                      lineHeight: '1.2',
                      '& .MuiAlert-icon': {
                        fontSize: '1.2rem', // Reduce icon size
                      }
                    }}
                  >
                    The serviceability calculation does not include the loan repayments for this application.
                  </Alert>
                </Stack>
              </Grid>
            </React.Fragment>
          )}
        </Grid>
      </Grid>
    </React.Fragment>
  );
});

export default CustomerPersonalFinances;
