import React from "react";
import { Button, Card, Tooltip } from "@mui/material";

const Sidebar = (props) => {
  const {
    open,
    title,
    onClickFn,
    bottom,
    right,
    left,
    disabled,
    component,
    icon,
    forwardIcon,
    backwardIcon,
    overflowY = "auto",
  } = props;
  const isBannerVisible = process.env.REACT_APP_IS_BANNER?.toLowerCase() === "true" || false

  const handle = {
    paddingFn: () => {
      switch (title) {
        case "Expand lenders":
          return "12px 12px 12px 12px";
        case "Manual Quote":
          return "0px 0px 0px 0px";
        case "Notes":
          return "12px 0px";
        case "Comments":
          return "12px 0px";
        case "Activity log":
          return "12px 0px";
        case "Service log":
          return "12px 0px";
        default:
          break;
      }
    },
    widthFn: () => {
      switch (title) {
        case "Expand lenders":
          return `calc(${open ? 75 : 20}vw - 25px)`;
        case "Manual Quote":
          return `${open ? "calc(20vw - 25px)" : 0}`;
        case "Notes":
          return `${open ? "calc(25vw - 25px)" : 0}`;
        case "Comments":
          return `${open ? "calc(35vw - 35px)" : 0}`;
        case "Activity log":
          return `${open ? "calc(25vw - 25px)" : 0}`;
        case "Service log":
          return `${open ? "calc(25vw - 25px)" : 0}`;
        default:
          break;
      }
    },
    leftFn: () => {
      switch (title) {
        case "Notes":
          return left ? `${open ? "calc(25vw - 25px)" : "0px"}` : "";
        case "Comments":
          return left ? `${open ? "calc(35vw - 35px)" : "0px"}` : "";
        case "SMS (coming soon)":
          return left ? `${open ? "calc(25vw - 24px)" : "0px"}` : "";
        case "Emails (coming soon)":
          return left ? `${open ? "calc(25vw - 25px)" : "0px"}` : "";
        case "Activity log":
          return left ? `${open ? "calc(25vw - 25px)" : "0px"}` : "";
        case "Service log":
          return left ? `${open ? "calc(25vw - 25px)" : "0px"}` : "";
        default:
          break;
      }
    },
    topFn: () => {
      switch (title) {
        case "Expand lenders":
          return "146px";
        case "Manual Quote":
          return "203px";
        case "Notes":
          return "146px";
        case "Comments":
          return "203px";
        case "SMS (coming soon)":
          return "425px";
        case "Emails (coming soon)":
          return "370px";
        case "Activity log":
          return "260px";
        case "Service log":
          return "315px";
        default:
          break;
      }
    },
    rightFn: (right, title, open) => {
      if (right) {
        if (title === "Manual Quote" && open) {
          return "calc(20vw - 25px)";
        } else {
          const vwCalculation = open ? 75 : 20.5;
          const pxValue = open ? "25px" : "35px";
          return `calc(${vwCalculation}vw - ${pxValue})`;
        }
      }
      return "";
    },
  };

  return (
    <div>
      <Tooltip title={title} placement={title === "Expand lenders" || title === "Manual Quote" ? "left" : "right"}>
        <Button
          disabled={disabled}
          color={`${open ? "primary" : "secondary"}`}
          elevation={1}
          variant={`${open ? "contained" : "outlined"}`}
          style={{
            transition: title === "Manual Quote" ? "" : "all 0.5s ease-in-out 0s",
            padding: "5px 5px",
            minWidth: 0,
            position: "fixed",
            borderColor: "rgba(0, 0, 0, 0.10)",
            right: handle.rightFn(right, title, open),
            left: handle.leftFn(),
            bottom: left && !bottom && "742px",
            top: handle.topFn(),
            writingMode: "vertical-rl",
            textOrientation: "sideways-right",
            marginTop: "25px",
            zIndex: open ? "999" : "",
          }}
          onClick={onClickFn}
        >
          {icon && icon}
          {!icon && open ? forwardIcon || icon : backwardIcon}
        </Button>
      </Tooltip>

      <Card
        id="productSelectorWrapper"
        elevation={2}
        style={{
          background: "#fff",
          padding: handle.paddingFn(),
          width: handle.widthFn(),
          position: "fixed",
          overflowY: overflowY,
          overflowX: "hidden",
          height: "calc(100vh - 63px)",
          scrollbarColor: "white rebeccapurple",
          scrollbarWidth: "thin",
          right: right ? 0 : "",
          left: left ? 0 : "",
          top: 64,
          zIndex: 1,
          transition: title === "Manual Quote" ? "" : "all 0.5s ease-in-out 0s",
          ...(isBannerVisible && { margin: '35px auto 0' })
        }}
        sx={{
          "&.MuiCard-root::-webkit-scrollbar": {
            width: "7px",
            height: "7px",
          },
          "&.MuiCard-root::-webkit-scrollbar-track": {
            background: "#f1f1f1",
          },
          "&.MuiCard-root::-webkit-scrollbar-thumb": {
            backgroundColor: "#999",
          },
          "&.MuiCard-root::-webkit-scrollbar-thumb:hover": {
            background: "#616161",
          },
        }}
      >
        {/* {open && title === "Manual Quote" && component} */}
        {component}
      </Card>
    </div>
  );
};

export default Sidebar;
