import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { Grid } from "@mui/material";

import Lenders from "./lenders/Lender";
import UserDetails from "./profile/UserDetails";
import UserRolesList from "./UserRolesList";
import RolePermission from "./RolePermission";
import BillingWrapper from "../../containers/BillingWrapper";
import { userSelector } from "../../store/slices/userSlice";
import { userType } from "../../constants";
import SettingsSidebar from "../../layouts/SettingsSidebar";

export default function UserProfile() {
  const { path } = useParams();
  const userState = useSelector(userSelector);

  return (
    <>
      <Grid
        container
        item
        height="100%"
        width="100%"
        spacing={3}
        // style={{ padding: "30px 50px 100px" }}
        // justifyContent="center"
        // alignItems="center"
        direction="row"
      >
        <Grid
          container
          item
          xs={12}
          sm={3}
          md={2}
          lg={2}
          width="100%"
          // sm={10}
          // md={10}
          // xl={8}
          // height="100vh"
          justifyContent="start"
          alignItems="start"
          direction="column"
          // textAlign="center"
          // style={{ marginTop: "64px" }}
        >
          <SettingsSidebar {...{ path }} />
        </Grid>
        {path === "general" && (
          <Grid
            container
            item
            xs={12}
            sm={9}
            md={9}
            lg={8}
            width="100%"
            // sm={10}
            // md={10}
            // xl={8}
            // height="100vh"
            justifyContent="start"
            alignItems="start"
            direction="column"
            // textAlign="center"
            // style={{ marginTop: "64px" }}
          >
            <UserDetails />
          </Grid>
        )}

        {path === "lenders" && (
          <Grid
            container
            item
            xs={12}
            sm={9}
            md={9}
            lg={8}
            width="100%"
            // sm={10}
            // md={10}
            // xl={8}
            // height="100vh"
            justifyContent="start"
            alignItems="start"
            direction="column"
            // textAlign="center"
            // style={{ marginTop: "64px" }}
          >
            <Lenders />
          </Grid>
        )}
        {[userType.owner, userType.master].includes(userState?.userType) && path === "user" && (
          <Grid
            container
            item
            xs={12}
            sm={9}
            md={9}
            lg={8}
            width="100%"
            justifyContent="start"
            alignItems="start"
            direction="column"
          >
            <BillingWrapper title={"User"} >
            <UserRolesList />
            </BillingWrapper>
          </Grid>
        )}
        {[userType.owner, userType.master].includes(userState?.userType) && path === "rolepermissions" && (
          <Grid
            container
            item
            xs={12}
            sm={9}
            md={9}
            lg={8}
            width="100%"
            justifyContent="start"
            alignItems="start"
            direction="column"
          >
            <BillingWrapper title={"Role & Permissions"}  >
            <RolePermission />
            </BillingWrapper>
          </Grid>
        )}
      </Grid>
    </>
  );
}
