import User from "./User";

export default function Register() {
  // useEffect(() => {
  //   if (!user) navigate("/login");
  // }, [user]);

  return (
    <>
      <User />
      {/* <ConfirmEmail />
      <Organisation /> */}
    </>
  );
}
