import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import _ from "lodash";
import { reusableFetch } from "../../utils/useAxios";

export const getAllApplications = createAsyncThunk(
  "applications/get",
  async (data, { rejectWithValue }) => {
    const applications = await reusableFetch(`application/all`, "POST", data, rejectWithValue);
    return applications;
  },
);

export const applicationsSlice = createSlice({
  name: "applications",
  initialState: {
    isLoading: false,
  },
  reducers: {
    saveValue: (state, action) => _.merge(state, action.payload),
  },
  extraReducers: {
    [getAllApplications.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.isLoading = false;
      state.isSuccess = true;
      state.applications = payload?.data?.data;
    },
    [getAllApplications.pending]: (state) => {
      state.isLoading = true;
      state.isFetching = true;
    },
    [getAllApplications.rejected]: (state, { payload }) => {
      state.isLoading = false;
      state.isFetching = false;
      state.isError = true;
    },
  },
});


export const { saveValue } = applicationsSlice.actions;
export const applicationsSelector = (state) => state.applications;