import React from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";

const ConfirmationDialog = ({ isOpen, handle, message, setIsOpen, title }) => {
  const handleClose = () => {
    setIsOpen(false);
  };

  const handleProceed = () => {
    setIsOpen(false);
    handle();
  };

  return (
    <React.Fragment>
      <Dialog open={isOpen} onClose={handleClose} fullWidth maxWidth={"sm"}>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <DialogContentText>{message}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant="outlined">
            Cancel
          </Button>
          <Button onClick={handleProceed} variant="outlined">
            Proceed
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

export default ConfirmationDialog;